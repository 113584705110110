import "./App.css";
import logo from "./Dutton-Logo.svg"; // Import the logo
import phoneIcon from "./Phone.svg";
import React, { useEffect } from "react";
import illustration from "./Illustration.svg";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3"; // imported reCAPTCHA

/* Additional imports for submission form + validation*/
import { useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from "firebase/app-check";
import { getFirestore, collection, addDoc } from "firebase/firestore";

import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB1oQiLXLASts_qcHS__i5m1vr_VYB52lc",
  authDomain: "dutton-wholesale-coming-soon.firebaseapp.com",
  projectId: "dutton-wholesale-coming-soon",
  storageBucket: "dutton-wholesale-coming-soon.appspot.com",
  messagingSenderId: "1081440596163",
  appId: "1:1081440596163:web:292091255c12bd001af276",
  measurementId: "G-LQ1X4TDB92",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth();

const siteRecaptchaKey = "6LeoHFYqAAAAAHfAZuJ2sktp-YTZZBHz3DEpwTwC";

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(siteRecaptchaKey),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

const isEmail = (email) =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.{1,3}[a-zA-Z]{1,4}$/i.test(email); //regex pattern email validator

function App() {
  const [values, setValues] = useState({ email: "" }); //value of email inputs
  const [msg, setMsg] = useState(""); //handles all messages
  const [loading, setLoading] = useState(false); // tracks if the form is submitting
  const [validToken, setValidToken] = useState(false); // tracks if the reCAPTCHA token is valid
  const [anonUser, setAnonUser] = useState(null); // tracks the anonymous user

  // Get user IP from headers
  const getUserIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (e) {
      console.error("Error getting user IP: ", e);
      return "Unknown";
    }
  };

  //event handler
  async function handleSubmit(event) {
    //Validation and submisison of form.
    event.preventDefault();

    if (checkToken() === false) {
      setMsg("An error occured. Please try again. Not submitting");
      setValidToken(false);
      setLoading(false);
      return;
    }

    if (!anonUser) {
      setMsg("An error occured. Please refresh and again. Not submitting");
      setLoading(false);
      return;
    }

    if (!values.email) {
      setMsg("Email is required.");
      return;
    } else if (!isEmail(values.email)) {
      setMsg("Please enter a valid email address.");
      return;
    }

    setLoading(true); //start loading state

    logEvent(analytics, "submit_form_start");
    setMsg(""); // Clear any previous messages if no errors

    const userIP = await getUserIP();

    try {
      const docRef = await addDoc(
        collection(db, "form_submission"),
        {
          email: values.email,
          timestamp: new Date(),
          userIP,
        },
        { merge: true }
      )
        .then((response) => {
          logEvent(analytics, "submit_form_success");
          setMsg("Successful!"); // Successly subscribed
          return response;
        })
        .catch((error) => {
          logEvent(analytics, "submit_form_error");
          setMsg("An error occured. Please try again. ");
          console.error("Error:", error);
        });
    } catch (e) {
      console.error("Error adding document: ", e);
    } finally {
      setLoading(false);
      setValues({ email: "" }); // Clear the email input
    }
  }

  function setEmail(event) {
    //update the email state when a change in input
    setValues((values) => ({ ...values, email: event.target.value })); //takes input, copies and updates email with event target value expression
  }

  const checkToken = async () => {
    try {
      const appCheckTokenResponse = await getToken(appCheck, true);
      if (appCheckTokenResponse.error) {
        console.error(
          "Error fetching reCAPTCHA token: ",
          appCheckTokenResponse.error
        );
        return false;
      }
      return true;
    } catch (err) {
      console.error("Error fetching reCAPTCHA token: ", err);
      return false;
    }
  };

  useEffect(() => {
    logEvent(analytics, "page_view");
  }, []);

  // Check appCheck token
  useEffect(() => {
    checkToken();
  }, []);

  // Authenticate the user anonymously
  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        console.log("User signed in anonymously");
        setAnonUser(auth.currentUser);
      })
      .catch((error) => {
        console.error("Error signing in anonymously: ", error);
        setAnonUser(null);
      });
  }, []);

  return (
    <div className="construction-page">
      <header className="header">
        <div className="phone-number">
          <img src={phoneIcon} alt="Phone Icon" className="phone-icon" />{" "}
          {/* Add the phone icon here */}
          <span>
            <a href="tel:1300661566" className="disable-call">
              1300 66 15 66
            </a>
          </span>
        </div>
      </header>

      <main className="main-content">
        <div className="info-section">
          <div className="logo">
            <img src={logo} alt="Dutton Logo" /> {/* Logo */}
          </div>
          <h2>Brand New Website Under Construction</h2>
          <p className="Sub-info" style={{ marginBottom: 35 }}>
            Get notified about all the latest updates
          </p>
          {/* Form submission stars here  */}
          <form className="subscribe-form" onSubmit={handleSubmit}>
            {" "}
            {/* Calls submission function   */}
            <input
              type="text"
              placeholder="Email"
              value={values.email}
              onChange={setEmail}
              disabled={loading} //Disable button when loading
            />{" "}
            {/* calls function to update expression   */}
            <button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Subscribe"} {/* Button feedback */}
            </button>
          </form>
          <br />
          {/* Display feedback message */}
          {msg && (
            <span
              style={{
                fontWeight: "bold",
                color: msg.startsWith("Success") ? "green" : "red",
              }}
            >
              {msg}
            </span>
          )}

          <p className="privacy-statement">
            By clicking "Subscribe" you agree to Dutton Wholesale
            <a href="https://www.duttongroup.com.au/privacy-policy">
              {" "}
              Privacy Statement
            </a>
          </p>
        </div>
        <div className="illustration">
          <img src={illustration} alt="Man sitting and using phone" />
        </div>
      </main>
    </div>
  );
}

export default App;
